<route lang="json">
{
	"name": "session-viewer",
	"meta": {
		"requiresAuth": true,
		"displayName": "Térkép",
		"icon": "map-location-dot",
		"index": 250,
		"isDashboardItem": true,
		"hideOnMobile": true
	}
}
</route>
<script setup lang="ts">
//TODO: itt kurvanagy a káosz
import MapBase from "./map/__components/MapBase.vue"
import { Card } from "@/components/ui"
import SessionSearchBar from "./map/__components/SessionSearchBar.vue"
import SessionGeometry from "./map/__components/SessionGeometry.vue"
import LoginsList from "./map/__components/LoginsList.vue"
import SessionProvider from "@/components/provider/SessionsProvider.vue"
import SessionHistory from "@/components/app/SessionHistory/SessionHistory.vue"
import Marker from "./map/__components/Marker.vue"
import type { FindSessionsRequest } from "@infobex/corrigo-api"
import { useRouter } from "vue-router/auto"

import SessionGrouper from "./map/__components/SessionGrouper.vue"
import type { Extent } from "ol/extent"
import dayjs from "dayjs"
import { useRoute } from "vue-router"
import { useAppCore } from "../stores/core"
import { computed } from "vue"
import { pruneFalsyProperties } from "../utils/object"

const enum BOUNDS {
	UPPER = 30,
	LOWER = 3
}

const ROUNDING_FACTOR = 10 ** -4

definePage({
	props: route => {
		return { ...route.query }
	}
})

defineProps<{
	user_id?: number
	login_id?: number
	location?: string
	session_id?: number
	start_date?: string
	end_date?: string
	srid?: number
}>()

const route = useRoute()
const router = useRouter()

function mergeQueryString(params: FindSessionsRequest & { session_id?: number }) {
	console.log("shit")

	const current = route.query ?? {}

	const query = pruneFalsyProperties({
		...params,
		session_id: current.session_id ?? undefined
	})

	router.replace({ query })
}

function isInsideBounds([x, y]: number[], [x_min, y_min, x_max, y_max]: Extent) {
	return x >= x_min && x <= x_max && y >= y_min && y <= y_max
}

function formatSessionDate(start: string | Date, end: string | Date) {
	const [d1, d2] = [dayjs(start), dayjs(end)]
	return `${d1.format("MMM D. HH:mm")} - ${d2.format("HH:mm")}`
}

function getRoundedCoordinates([x, y]: number[], factor = ROUNDING_FACTOR) {
	return [Math.round(x * factor), Math.round(y * factor)]
}

function mapCoordinatesToString([x, y]: number[]) {
	return `${x}-${y}`
}

const { state } = useAppCore()

const is_administrative = computed(() => {
	return state.currentUser?.roles?.includes("ORG_ADM") || state.currentUser?.roles?.includes("ADMIN")
})
</script>
<template>
	<SessionProvider v-slot="{ sessions, pending }" v-bind="route.query" :srid="3857">
		<Card no-padding class="flex flex-col overflow-visible" style="width: 30rem">
			<SessionSearchBar v-if="is_administrative" @submit="mergeQueryString" />
			<LoginsList v-else :login_id="login_id" @submit="mergeQueryString" />
			<SessionHistory v-bind="{ sessions, pending }" />
		</Card>

		<MapBase class="overflow-hidden">
			<template #features="{ resolution, bounds, setCenter, setResolution }">
				<!-- Render selected session -->
				<SessionGeometry v-if="session_id" v-bind="{ session_id }" :key="session_id" setcenter :srid="3857" />

				<!-- Render entry points -->
				<template v-else>
					<!-- Far view 1, group by city -->
					<SessionGrouper
						v-if="resolution > BOUNDS.UPPER"
						v-slot="{ groups: groupsByCity }"
						avg-coords
						:sessions="sessions"
						:group-by="session => session.city ?? 'Ismeretlen'"
					>
						<!-- 
						<span class="map-debug-msg">
							RESOLUTION: {{ resolution }}, grouping by city (res > {{ BOUNDS.UPPER }})
						</span>
						-->
						<template v-for="group of groupsByCity">
							<Marker
								v-if="isInsideBounds(group.avg!, bounds)"
								:key="group.name"
								:coordinates="group.avg!"
								@click="
									() => {
										setCenter(group.avg!)
										setResolution(BOUNDS.UPPER)
									}
								"
							>
								<span class="text-sm">
									<strong class="text-sm text-primary-light">{{ group.name }}</strong>
									<span class="opacity-75">({{ group.contents.length }})</span>
								</span>
							</Marker>
						</template>
					</SessionGrouper>

					<!--  Far view 2, group by coordinates -->
					<SessionGrouper
						v-if="resolution <= BOUNDS.UPPER && resolution > BOUNDS.LOWER"
						v-slot="{ groups: groupsByRoundedCoordinates }"
						:sessions="sessions"
						:group-by="session => mapCoordinatesToString(getRoundedCoordinates(session.entry_point.coordinates))"
						avg-coords
					>
						<!--
						<span class="map-debug-msg">
							RESOLUTION: {{ resolution }}, grouping by coordinates, rounding:
							{{ (1 / ROUNDING_FACTOR).toFixed() }} (res: {{ BOUNDS.LOWER }}-{{ BOUNDS.UPPER }})
						</span>
						-->

						<template v-for="group of groupsByRoundedCoordinates" :key="group.name">
							<template v-if="isInsideBounds(group.avg!, bounds)">
								<!-- Groups with multiple sessions -->
								<Marker
									v-if="group.contents.length > 10"
									:coordinates="group.avg!"
									@click="
										() => {
											setCenter(group.avg!)
											setResolution(BOUNDS.LOWER)
										}
									"
								>
									<span class="text-sm">
										<strong class="text-sm text-primary-light">{{ group.contents.length }}</strong>
									</span>
								</Marker>

								<!-- Groups with single session-->
								<template v-else>
									<template v-for="session of group.contents" :key="session.session_id">
										<Marker
											v-if="isInsideBounds(session.entry_point.coordinates, bounds)"
											:coordinates="session.entry_point.coordinates as [number, number]"
											@click="() => mergeQueryString({ session_id: session.session_id })"
										>
											<span class="text-sm">
												<strong class="text-sm text-primary-light">{{ session.login_name }}</strong>
												<br />
												<span>
													{{ formatSessionDate(session.start_time, session.end_time) }}
												</span>
											</span>
										</Marker>
									</template>
								</template>

								<!-- 	<Marker
									v-else
									:coordinates="(group.contents[0].entry_point.coordinates as [number, number])"
									@click="() => writeParamsToURL({ session_id: group.contents[0].session_id })"
								>
									<span class="text-sm">
										<strong class="text-sm text-primary-light">{{ group.contents[0].login_name }}</strong>
										<br />
										<span>
											{{ formatSessionDate(group.contents[0].start_time, group.contents[0].end_time) }}
										</span>
									</span>
								</Marker> -->
							</template>
						</template>
					</SessionGrouper>

					<!-- Closeup view -->
					<!-- <span class="map-debug-msg">
							RESOLUTION: {{ resolution }} no grouping (res &lt; {{ BOUNDS.LOWER }})
						</span> -->
					<template v-if="resolution <= BOUNDS.LOWER">
						<template v-for="session of sessions" :key="session.session_id">
							<Marker
								v-if="isInsideBounds(session.entry_point.coordinates, bounds)"
								:coordinates="session.entry_point.coordinates as [number, number]"
								@click="() => mergeQueryString({ session_id: session.session_id })"
							>
								<span class="text-sm">
									<strong class="text-sm text-primary-light">{{ session.login_name }}</strong>
									<br />
									<span>
										{{ formatSessionDate(session.start_time, session.end_time) }}
									</span>
								</span>
							</Marker>
						</template>
					</template>
				</template>
			</template>
		</MapBase>
	</SessionProvider>
</template>
<style lang="scss">
.map-debug-msg {
	@apply text-black;
	@apply bg-red-500 bg-opacity-80;
	@apply font-bold;
	@apply absolute;
	@apply font-mono;
	@apply px-1;
	z-index: 10;
	left: 4px;
	bottom: 4px;
	display: none;
}

button.move-control {
	@apply transition-all;
	@apply bg-neutral-100;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	@apply p-1;
	// margin: 2px;
	line-height: normal;
	cursor: grab;

	&:hover {
		@apply text-primary;
		// @apply bg-black bg-opacity-10;
	}

	&:active {
		cursor: grabbing;
		@apply bg-primary text-white;
		@apply ring-primary ring-opacity-40;
	}
}
</style>
